<template>
  <div>
    <div class="title">
      <h2>{{ $t('menu.rocket') }}</h2>
    </div>
    <SelectCity class="select-city" @setCity="setCity" />

    <div class="type-pokemon">
      <div class="type-select" v-for="(t, k) in typesPokemon.filter(i => i.type === 1)" :key="k"
        @click.prevent="getItens(t.value, t.characters, t)" :class="{ active: select === t.value }">
        <div class="pok">
          <img :src="require(`../assets/pvp/type/${t.name}.png`)" />
        </div>
      </div>
      <div class="type-select">
        <div class="pok"></div>
      </div>
    </div>
    <div class="type-pokemon">
      <div class="type-select" v-for="(t, k) in typesPokemon.filter(i => i.type === 2)" :key="k"
        @click.prevent="getItens(t.value, t.characters, t)" :class="{ active: select === t.value }">
        <div class="pok">
          <img :src="require(`../assets/pvp/type/${t.name}.png`)" />
        </div>
      </div>
      <div class="type-select">
        <div class="pok"></div>
      </div>
    </div>
    <div class="type-pokemon">
      <div class="type-select" v-for="(t, k) in typesPokemon.filter(i => i.type === 3)" :key="k"
        @click.prevent="getItens(t.value, t.characters, t)" :class="{ active: select === t.value }">
        <div class="pok">
          <img v-if="t.img" :src="t.img" />
          <img v-else :src="require(`../assets/pvp/type/${t.name}.png`)" />
        </div>
      </div>
    </div>
    <div v-if="rockets.length">
      <label for="updateInterval">{{ $t('menu.update_interval') }}:</label>
      <select id="updateInterval" v-model="updateInterval" @change="resetTimer">
        <option value="1">1 {{ $t('menu.minutes') }}</option>
        <option value="2">2 {{ $t('menu.minutes') }}</option>
        <option value="3">3 {{ $t('menu.minutes') }}</option>
        <option value="4">4 {{ $t('menu.minutes') }}</option>
        <option value="5">5 {{ $t('menu.minutes') }}</option>
        <option value="8">8 {{ $t('menu.minutes') }}</option>
        <option value="10">10 {{ $t('menu.minutes') }}</option>
        <option value="15">15 {{ $t('menu.minutes') }}</option>
      </select>
    </div>
    <div v-if="rockets.length">
      <div
        class="text-xs text-gray-800 bg-amarelo p-1 mt-2.5 rounded font-bold py-3 mb-2 border border-solid border-roxo z-2">
        <i class="fa fa-info-circle"></i>
        {{ $t('menu.time_remaining') }}: {{ formattedTime }} {{ $t('menu.seconds') }}
      </div>
    </div>
    <div>
      <Loading :active="loading" />
      <br />
      <div v-if="erro">
        {{ $t('rocket.erro') }}
      </div>
      <div>
        <div class="btn btn-velocity" href="#" @click.prevent="selectVelocity" v-if="rockets.length">
          {{
          velocity
          ? $t('missao.velocityAtivado')
          : $t('missao.velocityDesativado')
          }}
        </div>
        <vue-flip active-click height="50px" width="100%" class="btn-container" v-if="rockets.length > 1">
          <template v-slot:front>
            <div class="btn" @click="setSaidaCopy">
              {{ $t('rocket.copiar') }}
            </div>
          </template>
          <template v-slot:back>
            <div class="btn">{{ $t('rocket.copiar') }}</div>
          </template>
        </vue-flip>
        <ul class="home">
          <li v-for="(r, k) in rockets" :key="k">
            <div v-if="k % 6 === 0">
              <strong>{{ select == '0' ? 'Kecleon' : select == '-1' ? 'Gimmighoul Coin' : 'Rocket' }} {{ k / 6 + 1
                }}</strong>
            </div>
            <table>
              <tr>
                <td class="coord">
                  <span class="coord" :style="r.copy ? 'text-decoration: line-through;' : ''">{{
                    r.coord }}</span>
                </td>
                <td class="tempo">
                  <span class="text-tempo">
                    <countdown :time="r.time * 1000" :transform="transform">
                      <template slot-scope="props">
                        {{ props.minutes }}:{{ props.seconds }}</template>
                    </countdown>
                  </span>
                </td>
                <td class="copy" v-if="!velocity">
                  <!-- <td class="copy" v-if="!velocity"> -->
                  <vue-flip active-click width="100px" height="45px" class="btn-content" v-model="r.copy">
                    <template v-slot:front>
                      <div class="btn" @click="getCopy(r, r.id)">
                        {{ $t('missao.copiar') }}
                      </div>
                    </template>
                    <template v-slot:back>
                      <div class="btn" @click="getCopy(r, r.id)">=)</div>
                    </template>
                  </vue-flip>
                </td>
                <td v-else>
                  <!--  <td v-else> -->
                  <vue-flip active-click width="100px" height="45px" class="btn-content" v-model="r.copy">
                    <template v-slot:front>
                      <div class="btn" @click="getCopyVelocity(r, r.id)">
                        {{ $t('missao.Abrir') }}
                      </div>
                    </template>
                    <template v-slot:back>
                      <div class="btn" @click="getCopyVelocity(r, r.id)">
                        =)
                      </div>
                    </template>
                  </vue-flip>
                </td>
              </tr>
            </table>

            <hr v-if="k % 6 === 5" />
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import moment from 'moment'
import Loading from '../components/Loading'
import SelectCity from '../components/Select'
import VueFlip from 'vue-flip'
import Countdown from '@chenfengyuan/vue-countdown'
const url = 'https://images.weserv.nl/?dpr=1&w=200&il&url=https://cdn.jsdelivr.net/gh/PokeMiners/pogo_assets/Images/Types/'

export default {
  components: {
    SelectCity,
    Loading,
    VueFlip,
    Countdown
  },
  computed: {
    formattedTime() {
      const minutes = Math.floor(this.remainingTime / 60)
      const seconds = this.remainingTime % 60
      return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    }
  },
  data: () => ({
    loading: false,
    lcl: 'us',
    velocity: false,
    erro: false,
    updateInterval: 3,
    remainingTime: 180,
    intervalId: null,
    timerId: null,
    characters: null,
    type: null,
    typesPokemon: [
      {
        name: 1,
        value: 1,
        characters: [44],
        title: 'giovanni',
        title_pt: 'giovanni',
        title_es: 'giovanni',
        type: 1
      },
      {
        name: 2,
        value: 2,
        characters: [42],
        title: 'arlo',
        title_pt: 'arlo',
        title_es: 'arlo',
        type: 2
      },
      {
        name: 3,
        value: 3,
        characters: [41],
        title: 'cliff',
        title_pt: 'cliff',
        title_es: 'cliff',
        type: 2
      },
      {
        name: 4,
        value: 4,
        characters: [43],
        title: 'sierra',
        title_pt: 'sierra',
        title_es: 'sierra',
        type: 2
      },
      {
        name: 5,
        value: 5,
        characters: [4, 5],
        title: 'grunt',
        title_pt: 'grunt',
        title_es: 'grunt',
        type: 2
      },
      {
        name: 6,
        value: 100,
        characters: [4],
        title: 'grunt',
        title_pt: 'grunt',
        title_es: 'grunt',
        type: 2
      },
      {
        name: 'showcase',
        value: -1,
        characters: [9997],
        title: 'showcase',
        title_pt: 'Vitrine',
        title_es: 'showcase',
        type: 1
      },
      {
        name: 'kecleon',
        value: 0,
        characters: [0],
        title: 'kecleon',
        title_pt: 'kecleon',
        title_es: 'kecleon',
        type: 1
      },
      {
        name: 'Magikarp',
        value: 39,
        characters: [39],
        title: 'Magikarp',
        title_pt: 'Magikarp',
        title_es: 'Magikarp',
        type: 1
      },
      {
        name: 'Snorlax',
        value: 115,
        characters: [5],
        title: 'Snorlax',
        title_pt: 'Snorlax',
        title_es: 'Snorlax',
        type: 1
      },
      /* {
        name: 'gimmighoul',
        value: -1,
        characters: [43],
        title: 'Gimmighoul Coin',
        title_pt: 'Gimmighoul Coin',
        title_es: 'Gimmighoul Coin',
        type: 1
      }, */
      {
        title: 'bug',
        title_pt: 'inseto',
        title_es: 'bicho',
        name: 'bug',
        value: 6,
        characters: [6, 7],
        type: 3,
        img: url + 'POKEMON_TYPE_BUG.png'
      },
      {
        title: 'ghost',
        title_pt: 'fantasma',
        title_es: 'fantasma',
        name: 'ghost',
        value: 7,
        characters: [47, 48],
        type: 3,
        img: url + 'POKEMON_TYPE_GHOST.png'
      },
      {
        title: 'dark',
        title_pt: 'noturno',
        title_es: 'siniestro',
        name: 'dark',
        value: 8,
        characters: [10, 11],
        type: 3,
        img: url + 'POKEMON_TYPE_DARK.png'
      },
      {
        title: 'dragon',
        title_pt: 'dragão',
        title_es: 'dragón',
        name: 'dragon',
        value: 9,
        characters: [12, 13],
        type: 3,
        img: url + 'POKEMON_TYPE_DRAGON.png'
      },
      {
        title: 'fairy',
        title_pt: 'fada',
        title_es: 'hada',
        name: 'fairy',
        value: 10,
        characters: [14, 15],
        type: 3,
        img: url + 'POKEMON_TYPE_FAIRY.png'
      },
      {
        title: 'fighting',
        title_pt: 'luta',
        title_es: 'lucha',
        name: 'fighting',
        value: 11,
        characters: [16, 17],
        type: 3,
        img: url + 'POKEMON_TYPE_FIGHTING.png'
      },
      {
        title: 'fire',
        title_pt: 'fogo',
        title_es: 'fuego',
        name: 'fire',
        value: 12,
        characters: [18, 19],
        type: 3,
        img: url + 'POKEMON_TYPE_FIRE.png'
      },
      {
        title: 'flying',
        title_pt: 'voador',
        title_es: 'volador',
        name: 'flying',
        value: 13,
        characters: [20, 21],
        type: 3,
        img: url + 'POKEMON_TYPE_FLYING.png'
      },
      {
        title: 'grass',
        title_pt: 'planta',
        title_es: 'planta',
        name: 'grass',
        value: 14,
        characters: [22, 23],
        type: 3,
        img: url + 'POKEMON_TYPE_GRASS.png'
      },
      {
        title: 'ground',
        title_pt: 'terrestre',
        title_es: 'tierra',
        name: 'ground',
        value: 15,
        characters: [24, 25],
        type: 3,
        img: url + 'POKEMON_TYPE_GROUND.png'
      },
      {
        title: 'ice',
        title_pt: 'gelo',
        title_es: 'hielo',
        name: 'ice',
        value: 16,
        characters: [26, 27],
        type: 3,
        img: url + 'POKEMON_TYPE_ICE.png'
      },
      {
        title: 'normal',
        title_pt: 'normal',
        title_es: 'normal',
        name: 'normal',
        value: 17,
        characters: [30, 31],
        type: 3,
        img: url + 'POKEMON_TYPE_NORMAL.png'
      },
      {
        title: 'poison',
        title_pt: 'venenoso',
        title_es: 'veneno',
        name: 'poison',
        value: 18,
        characters: [32, 33],
        type: 3,
        img: url + 'POKEMON_TYPE_POISON.png'
      },
      {
        title: 'psychic',
        title_pt: 'psíquico',
        title_es: 'psíquico',
        name: 'psychic',
        value: 19,
        characters: [34, 35],
        type: 3,
        img: url + 'POKEMON_TYPE_PSYCHIC.png'
      },
      {
        title: 'rock',
        title_pt: 'pedra',
        title_es: 'roca',
        name: 'rock',
        value: 20,
        characters: [36, 37],
        type: 3,
        img: url + 'POKEMON_TYPE_ROCK.png'
      },
      {
        title: 'steel',
        title_pt: 'metal',
        title_es: 'steel',
        name: 'steel',
        value: 21,
        characters: [28, 29],
        type: 3,
        img: url + 'POKEMON_TYPE_STEEL.png'
      },
      {
        title: 'water',
        title_pt: 'agua',
        title_es: 'agua',
        name: 'water',
        value: 22,
        characters: [38, 39],
        type: 3,
        img: url + 'POKEMON_TYPE_WATER.png'
      },
      {
        title: 'electric',
        title_pt: 'eléctrico',
        title_es: 'eléctrico',
        name: 'electric',
        value: 23,
        characters: [50, 49],
        type: 3,
        img: url + 'POKEMON_TYPE_ELECTRIC.png'
      }
    ],
    itens: [],
    select: '',
    rockets: [],
    rocketsList: ''
  }),
  methods: {
    setCity(item) {
      this.loading = true
      this.lcl = item.slug
      this.itens = []
      this.select = ''
      setTimeout(() => {
        this.loading = false
      }, 300)
    },

    getLugar(lugar) {
      switch (lugar) {
        case 'ca':
          return 'Vancouver'
        case 'us':
          return 'Nova York'
        case 'gb':
          return 'Londres'
        case 'sg':
          return 'Singapura'
        case 'au':
          return 'Sydney'
        default:
          return ''
      }
    },

    arendondar(num, dec) {
      return (+(Math.round(num + 'e+' + dec) + 'e-' + dec)).toFixed(dec)
    },

    selectVelocity() {
      localStorage.setItem('_velocityRocket', !this.velocity)
      this.velocity = !this.velocity
    },

    getCopyVelocity(r, key) {
      //r.copy = !r.copy

      const cache = JSON.parse(localStorage.getItem('_rocket'))
      cache.map(i => {
        if (i.id === key) {
          i.copy = !r.copy
        }
        return i
      })
      localStorage.setItem('_rocket', JSON.stringify(cache))
      if (!r.copy) {
        window.open(`https://pk.md/${r.coord}`, '_blank')
      }
    },

    getDsp(epoch) {
      var hours = epoch / 3600,
        minutes = (hours % 1) * 60
      return (
        (Math.floor(hours) !== 0 ? `${Math.floor(hours)}h` : '') +
        `${Math.floor(minutes)}m`
      )
    },
    transform(props) {
      Object.entries(props).forEach(([key, value]) => {
        const digits = value < 10 ? `0${value}` : value
        props[key] = `${digits}`
      })

      return props
    },
    tempo(dist) {
      var myTime = ''

      if (dist <= 0.01) {
        myTime = '0seg'
      } else if (dist <= 0.1) {
        myTime = '3seg'
      } else if (dist <= 0.5) {
        myTime = '15seg'
      } else if (dist <= 1) {
        myTime = '30seg'
      } else if (dist <= 2) {
        myTime = '1min'
      } else if (dist <= 5) {
        myTime = '2min'
      } else if (dist <= 6) {
        myTime = '4min'
      } else if (dist <= 7) {
        myTime = '5min'
      } else if (dist <= 8) {
        myTime = '6min'
      } else if (dist <= 10) {
        myTime = '7min'
      } else if (dist <= 12) {
        myTime = '8min'
      } else if (dist <= 14) {
        myTime = '9min'
      } else if (dist <= 18) {
        myTime = '10min'
      } else if (dist <= 21) {
        myTime = '13min'
      } else if (dist <= 26) {
        myTime = '15min'
      } else if (dist <= 42) {
        myTime = '19min'
      } else if (dist <= 65) {
        myTime = '22min'
      } else if (dist <= 70) {
        myTime = '24min'
      } else if (dist <= 81) {
        myTime = '25min'
      } else if (dist <= 100) {
        myTime = '35min'
      } else if (dist <= 220) {
        myTime = '40min'
      } else if (dist <= 250) {
        myTime = '45min'
      } else if (dist <= 350) {
        myTime = '51min'
      } else if (dist <= 375) {
        myTime = '54min'
      } else if (dist <= 460) {
        myTime = '1hor 2min'
      } else if (dist <= 500) {
        myTime = '1hor 5min'
      } else if (dist <= 565) {
        myTime = '1hor 9min'
      } else if (dist <= 700) {
        myTime = '1hor 18min'
      } else if (dist <= 800) {
        myTime = '1hor 24min'
      } else if (dist <= 900) {
        myTime = '1hor 32min'
      } else if (dist <= 1000) {
        myTime = '1hor 39min'
      } else if (dist <= 1100) {
        myTime = '1hor 47min'
      } else if (dist <= 1200) {
        myTime = '1hor 54min'
      } else if (dist <= 1300) {
        myTime = '1hor 57min'
      } else {
        myTime = '2hor'
      }
      return myTime
    },
    calcularDistancia(lat1, lon1, lat2, lon2) {
      // double R = 6378.137;//Radio de la tierra en km
      var R = 6371.0
      var dLat = this.rad(lat2 - lat1)
      var dLong = this.rad(lon2 - lon1)

      var a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(this.rad(lat1)) *
        Math.cos(this.rad(lat2)) *
        Math.sin(dLong / 2) *
        Math.sin(dLong / 2)
      var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a))
      var d = R * c

      return d
    },
    rad(x) {
      return (x * Math.PI) / 180
    },
    async getItens(select, characters, type) {
      this.rockets = []
      this.select = select
      this.characters = characters
      this.type = type

      this.loading = true
      const itens = await axios.get(`${this.$url}/rockart`, {
        params: {
          characters,
          lc: this.lcl
        }
      })
      let title = ''
      switch (this.$i18n.locale) {
        case 'pt-br':
          title = type.title_pt
          break
        case 'es-es':
          title = type.title_es
          break
        default:
          title = type.title
          break
      }
      // eslint-disable-next-line no-unused-vars
      var coords = `【Ｃｏｏｒｄｓ ＧＯ】\n\nRocket - ${title}\n${this.getLugar(
        this.lcl
      )}\n${moment().format('L')}\n\n`
      const arrayTag = ['C', 'O', 'O', 'R', 'D', 'S', 'G', 'O']
      var inicio = 0
      var rocket = []
      var totalArrayTag = arrayTag.length

      let dados = itens.data
      var cc = dados.map(
        i =>
          `${this.arendondar(i.latitude, 6)},${this.arendondar(i.longitude, 6)}`
      )
      var listaCC = []

      cc.forEach(i => {
        let x = i.split(',')
        if (x.length === 2) {
          if (!isNaN(x[0]) && !isNaN(x[1])) {
            listaCC.push(x)
          }
        }
      })

      var listaFinal = []
      var distancias = []
      var tam = listaCC.length

      if (tam > 1) {
        var ccInicio = listaCC[0]
        listaCC.splice(0, 1)
        listaFinal.push(ccInicio)
        while (listaCC.length > 1) {
          var dMin = 1000000000
          var pos = -1
          for (let i = 0; i < listaCC.length; i++) {
            var dx = this.calcularDistancia(
              ccInicio[0],
              ccInicio[1],
              listaCC[i][0],
              listaCC[i][1]
            )
            if (dx < dMin) {
              pos = i
              dMin = dx
            }
          }
          listaFinal.push(listaCC[pos])
          distancias.push(dMin)
          ccInicio = listaCC[pos]
          listaCC.splice(pos, 1)
        }
        var coordenadas = []
        var tempos = []
        var penultimo = []
        for (let x = 0; x < listaFinal.length; x++) {
          coordenadas.push(
            `${this.arendondar(listaFinal[x][0], 6)},${this.arendondar(
              listaFinal[x][1],
              6
            )}`
          )
          if (x === listaFinal.length - 1) {
            penultimo = listaFinal[x]
          }
        }
        var ultimo = []
        coordenadas.push(
          `${this.arendondar(listaCC[0][0], 6)},${this.arendondar(
            listaCC[0][1],
            6
          )}`
        )
        ultimo = listaCC[0]
        // eslint-disable-next-line no-unused-vars
        var distFinal = 0
        tempos.push('0 seg')
        distancias.forEach(x => {
          distFinal += x
          tempos.push(this.tempo(x))
        })
        var disRest = this.calcularDistancia(
          penultimo[0],
          penultimo[1],
          ultimo[0],
          ultimo[1]
        )
        distFinal += disRest
        tempos.push(this.tempo(disRest))
        var set = 1
        // eslint-disable-next-line no-unused-vars
        var calculoFinal = ''
        for (let x = 1; x <= coordenadas.length; x++) {
          if (x === 1) {
            calculoFinal += `Total: ${coordenadas.length} \n\nSᴇᴛ ${set}`
            set++
          }
          calculoFinal += `\n${coordenadas[x - 1]} [${tempos[x - 1]}]`
          if (x % 3 === 0 && x !== coordenadas.length) {
            calculoFinal += `\n\nSᴇᴛ  ${set}`
            set++
          }
        }
        // eslint-disable-next-line no-unused-vars
        var dist = 'Distância:'
      }

      for (let item of dados) {
        var end = item.end - moment().unix()
        if (end > 300) {
          coords += `${arrayTag[inicio]} ${this.arendondar(
            item.latitude,
            6
          )},${this.arendondar(item.longitude, 6)} DSP: ${this.getDsp(end)}\n`

          var rocketNew = {}
          rocketNew.logo = arrayTag[inicio]
          rocketNew.coord = `${this.arendondar(
            item.latitude,
            6
          )},${this.arendondar(item.longitude, 6)}`
          rocketNew.dsp = this.getDsp(end)
          rocketNew.end = end
          rocketNew.copy = false
          rocketNew.time = end
          rocketNew.copy3 = false
          rocketNew.id = Math.random()
          rocket.push(rocketNew)
          inicio = inicio === totalArrayTag - 1 ? 0 : inicio + 1
        }
        if (rocket.length >= 48) {
          break
        }
      }
      this.rockets = rocket
      localStorage.setItem('_rocket', JSON.stringify(rocket))
      coords += `\nTotal: ${rocket.length}\n\nRota gerada em: Coords GO\nhttps://coordsgo.com/`
      this.rocketsList = coords
      if (!this.rockets.length) {
        this.erro = true
      } else {
        this.erro = false
      }
      setTimeout(() => {
        this.loading = false
      }, 300)
      this.resetTimer()
    },
    setSaidaCopy() {
      this.$copyText(this.rocketsList).then(
        () => null,
        () => alert('Erro')
      )
    },
    getCopy(r, key) {
      //r.copy = !r.copy
      const cache = JSON.parse(localStorage.getItem('_rocket'))
      cache.map(i => {
        if (i.id === key) {
          i.copy = !r.copy
        }
        return i
      })
      localStorage.setItem('_rocket', JSON.stringify(cache))
      if (!r.copy) {
        this.$copyText(r.coord).then(
          () => null,
          () => alert('Erro')
        )
      }
    },
    resetTimer() {
      clearInterval(this.timerId)
      clearInterval(this.intervalId)
      this.startTimer()
    },
    startTimer() {
      this.remainingTime = this.updateInterval * 60
      this.timerId = setInterval(this.updateTime, 1000)
      this.intervalId = setInterval(() => {
        this.getItens(this.select, this.characters, this.type) // Supondo que você tenha essas variáveis
      }, this.updateInterval * 60 * 1000)
    },
    updateTime() {
      if (this.remainingTime > 0) {
        this.remainingTime--
      } else {
        this.remainingTime = this.updateInterval * 60
      }
    },
  },
  mounted() {
    document.title = `${this.$route.meta.lang} APP Coordsgo.com - ${this.$route.meta.titulo}`
    this.end = false
    if (localStorage.getItem('_rocket')) {
      this.active = true
      this.rockets = []
    } else {
      this.rockets = []
    }
    if (localStorage.getItem('_velocityRocket')?.length) {
      this.velocity =
        localStorage.getItem('_velocityRocket') === 'true' ? true : false
    } else {
      this.velocity = false
    }
    //this.getListCoords(1)
  }
}
</script>

<style lang="scss" scoped>
.title {
  h2 {
    display: flex;
    justify-content: space-between;
    color: #673bb7;
    margin: 10px 0;
    font-size: 23px;
    border-bottom: 1px solid #673bb7;
    font-weight: bold;
  }
}

.select-city {
  margin-top: 10px;
}

.type-pokemon {
  display: flex;
  flex: 1;
  justify-content: space-around;
  flex-wrap: wrap;
  margin-top: 10px;

  .pok {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42px;
    height: 42px;

    img {
      height: 25px;
    }
  }
}

.type-select {
  border: 2px solid transparent;
  margin: 5px;

  .pok {
    img {
      height: 35px;
    }
  }
}

.active {
  border-color: #ccc;
}

h3 {
  padding-bottom: 5px;
  padding-top: 5px;
  font-size: 20px;
  font-weight: bold;
}
</style>
<style lang="scss">
ul.home {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
  line-height: 30px;
}

li a {
  color: #2c3e50;
  text-decoration: none;
}

.coord {
  width: 200px;
}

textarea {
  width: 100%;
  margin: 10px auto;
  border: 2px solid #673bb7;
  padding: 10px;
  box-sizing: border-box;
}

table {
  margin: 0 auto;
  width: 100%;
}

td {
  text-align: left;
}

td:nth-child(2) {
  width: 70px;
}

hr {
  border-bottom: 1px dashed #ccc;
  border-style: dashed;
  width: 300px;
}

.list-corpo {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

button {
  font-size: 18px;
  padding: 5px;
  margin: 0 5px;
}

.whats {
  margin-top: 4px;
}

.back {
  background-color: #ffc107;
}

.front {
  background-color: #673ab7;
}

.back,
.front {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  width: 100%;
  height: 100%;
}

.back {
  transform: rotateY(180deg);
}

.back,
.front {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
}

.back,
.flipper,
.front {
  transform-style: preserve-3d;
  border-radius: 5px;
}

.btn-content {
  margin: 0 auto;
}

.btn-container {
  flex: 1;
  margin: 0 auto 15px auto;
}

.btn {
  display: flex;
  height: 50px;
  width: 100%;
  align-items: center;
  justify-content: center;
}

.text-tempo {
  background-color: #673bb7;
  color: #fff;
  font-size: 14px;
  padding: 4px;
}

.btn-down {
  background: #673bb7;
  color: #fff;
  text-decoration: none;
  height: 50px;
  flex: 1;
  border-radius: 5px;
}

.btn-yellow {
  background-color: #ffc107;
  color: #fff;
  text-decoration: none;
  height: 50px;
  flex: 1;
  border-radius: 5px;
}

.btn-container {
  flex: 1;
  margin: 0 3px 15px 3px;
}

.list {
  padding: 5px;
  box-sizing: border-box;
  text-align: initial;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  overflow: hidden;
  //text-overflow: ellipsis;
  //white-space: nowrap;
  flex: 1;
}

.list small {
  font-size: 12px;
  font-weight: normal;
}

.date {
  font-size: 10px !important;
}

.list.active {
  background: rgb(103 59 183 / 21%);
}

.list-content {
  cursor: pointer;
  font-weight: normal;
  font-size: 13px;
}

.list-content .btn-down {
  width: 120px;
  height: 35px;
}

h2 {
  display: flex;
  justify-content: space-between;
  color: #673bb7;
  margin: 10px 0;
}

.vue-ads-leading-loose {
  display: none;
}

.vue-ads-justify-end {
  justify-content: flex-start;
}

.vue-ads-bg-teal-500 {
  background-color: #673bb7;
}

.vue-ads-flex {
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  margin: 10px 0;
}

.vue-ads-flex-grow {
  flex-grow: 0;
  flex: 1;
  justify-content: space-between;
}

.yellow {
  background-color: #ffc107 !important;
}

.logo {
  background: #673bb7;
  width: 40px;
  height: 40px;
  font-size: 32px;
  color: #fff;
  margin-left: 15px;
}

.logo .c {
  position: relative;
  top: -12px;
  left: -4px;
}
</style>

<style lang="scss" scoped>
.btn-copy {
  color: #fff;
  background: #673ab7;
  width: 110px !important;
  margin: 0 auto;
  text-align: center;
  height: 25px !important;
  font-size: 12px;
}

.btn-velocity {
  color: #fff;
  background: #673ab7;
  width: 130px !important;
  margin: 0 auto;
  text-align: center;
  height: 25px !important;
  font-size: 12px;
  margin-bottom: 20px;
  border-radius: 5px;
}

.cards {
  border-radius: 5px;
  font-size: 14px;
}

#updateInterval {
  margin: 10px;
  padding: 5px;
  border: 1px solid #4b5563;
}
</style>
